import { ApiConstModel } from './ApiConstModel';

const API_CONST: ApiConstModel = {
  CONFIG: { METHOD: 'GET', PATH: '/misc/config-list', DATA_KEY: 'configList' },

  /**
   * City
   */
  CITIES: { METHOD: 'GET', PATH: '/cities', DATA_KEY: 'cities' },
  CITY: { METHOD: 'GET', PATH: '/cities/{cityId}', DATA_KEY: 'city' },
  CITY_INFO: { METHOD: 'GET', PATH: '/cities/{cityId}/information', DATA_KEY: 'city-info' },

  /**
   * Feedbacks
   */
  FEEDBACKS: { METHOD: 'GET', PATH: '/feedbacks', DATA_KEY: 'feedbacks' },
  SEND_FEEDBACK: { METHOD: 'POST', PATH: '/feedbacks', DATA_KEY: 'send-feedback' },
  USER_FEEDBACKS: { METHOD: 'GET', PATH: '/feedbacks/user', DATA_KEY: 'user-feedbacks' },

  /**
   * POI
   */
  POI_CATEGORIES: { METHOD: 'GET', PATH: '/poi-categories', DATA_KEY: 'poi-categories' },
  POIS: { METHOD: 'GET', PATH: '/pois', DATA_KEY: 'pois' },
  POI: { METHOD: 'GET', PATH: '/pois/{poiId}', DATA_KEY: 'poi' },
  POIS_OPEN_SEARCH: { METHOD: 'GET', PATH: '/os-search', DATA_KEY: 'pois-search' },
  POIS_SEARCH_AUTO_COMPLETE: { METHOD: 'GET', PATH: '/misc/autocomplete', DATA_KEY: 'auto-complete' },
  POIS_SEARCH_AUTO_COMPLETE_TAGS: { METHOD: 'GET', PATH: '/misc/autocompletetags', DATA_KEY: 'auto-complete-tags' },
  CUSTOM_POIS: { METHOD: 'GET', PATH: '/my-custom-pois', DATA_KEY: 'custom-pois' },

  /**
   * Top 10
   */
  TOP_TEN: { METHOD: 'GET', PATH: '/top10-pois', DATA_KEY: 'top-ten' },

  /**
   * User
   */
  REGISTER: { METHOD: 'POST', PATH: '/auth/register', DATA_KEY: 'token' },
  LOGIN_COGNITO: { METHOD: 'POST', PATH: '/auth/login-cognito', DATA_KEY: 'login-cognito' },
  LOGIN: { METHOD: 'POST', PATH: '/auth/login', DATA_KEY: 'token' },
  LOGIN_GUEST: { METHOD: 'POST', PATH: '/auth/guest-login', DATA_KEY: 'token' },
  LOGOUT: { METHOD: 'POST', PATH: '/auth/logout', DATA_KEY: 'logout' },
  LIGHT_REGISTER_LOGIN: { METHOD: 'POST', PATH: '/auth/light-register-login', DATA_KEY: 'token' },
  LIGHT_HASH_LOGIN: { METHOD: 'POST', PATH: '/auth/light-hash-login', DATA_KEY: 'token' },

  USER: { METHOD: 'GET', PATH: '/user', DATA_KEY: 'user' },
  USER_UPDATE: { METHOD: 'PUT', PATH: '/user', DATA_KEY: 'user' },
  USER_DELETE: { METHOD: 'DELETE', PATH: '/user', DATA_KEY: 'user-delete' },
  REFRESH_TOKEN: { METHOD: 'POST', PATH: '/auth/refresh-token', DATA_KEY: 'token' },
  USER_RESET_PASSWORD: { METHOD: 'POST', PATH: '/auth/reset-password', DATA_KEY: 'user-reset-password' },
  USER_UPDATE_PASSWORD: { METHOD: 'PUT', PATH: '/auth/reset-password', DATA_KEY: 'user-update-password' },

  /**
   * User Companions
   */
  COMPANIONS: { METHOD: 'GET', PATH: '/companions', DATA_KEY: 'companions' },
  COMPANION_ADD: { METHOD: 'POST', PATH: '/companions', DATA_KEY: 'companions-add' },
  COMPANION_UPDATE: { METHOD: 'PUT', PATH: '/companions/{companionId}', DATA_KEY: 'companions-update' },
  COMPANION_DELETE: { METHOD: 'DELETE', PATH: '/companions/{companionId}', DATA_KEY: 'companions-delete' },

  /**
   * Favorites
   */
  FAVORITES: { METHOD: 'GET', PATH: '/favorites', DATA_KEY: 'favorites' },
  FAVORITE_ADD: { METHOD: 'POST', PATH: '/favorites', DATA_KEY: 'favorite-add' },
  FAVORITE_DELETE: { METHOD: 'DELETE', PATH: '/favorites/{favoriteId}', DATA_KEY: 'favorite-delete' },

  /**
   * Reactions
   */
  REACTIONS: { METHOD: 'GET', PATH: '/reactions', DATA_KEY: 'reactions' },
  REACTION_ADD: { METHOD: 'POST', PATH: '/reactions', DATA_KEY: 'reactions-add' },
  REACTION_UPDATE: { METHOD: 'PUT', PATH: '/reactions/{reactionId}', DATA_KEY: 'reactions-update' },
  REACTION_DELETE: { METHOD: 'DELETE', PATH: '/reactions/{reactionId}', DATA_KEY: 'reactions-delete' },

  /**
   * Notifications
   */
  NOTIFICATIONS: { METHOD: 'GET', PATH: '/notification', DATA_KEY: 'notifications' },
  NOTIFICATIONS_UNSEEN: { METHOD: 'GET', PATH: '/notification/unseen', DATA_KEY: 'notifications-unseen' },
  NOTIFICATIONS_UPDATE_UNSEEN: { METHOD: 'PUT', PATH: '/notification/unseen', DATA_KEY: 'notifications-update-unseen' },
  NOTIFICATIONS_SETTINGS: { METHOD: 'GET', PATH: '/notification/settings', DATA_KEY: 'notifications-settings' },
  NOTIFICATIONS_UPDATE_SETTINGS: { METHOD: 'PUT', PATH: '/notification/settings', DATA_KEY: 'notifications-update-settings' },

  /**
   * Plan
   */
  PLAN: { METHOD: 'GET', PATH: '/plans/{planId}', DATA_KEY: 'plan' },
  PLAN_UPDATE: { METHOD: 'PUT', PATH: '/plans/{planId}', DATA_KEY: 'plan-update' },
  PLAN_ROUTE_URL: { METHOD: 'POST', PATH: '/misc/export-itinerary', DATA_KEY: 'plan-route-url' },

  /**
   * Step
   */
  STEP_ALTERNATIVES: { METHOD: 'GET', PATH: '/step/alternatives', DATA_KEY: 'step-alternatives' },
  STEP_ADD: { METHOD: 'POST', PATH: '/steps', DATA_KEY: 'step-add' },
  STEP_UPDATE: { METHOD: 'PUT', PATH: '/steps/{stepId}', DATA_KEY: 'step-update' },
  STEP_DELETE: { METHOD: 'DELETE', PATH: '/steps/{stepId}', DATA_KEY: 'step-delete' },

  /**
   * Questions
   */
  QUESTIONS: { METHOD: 'GET', PATH: '/trip/questions', DATA_KEY: 'questions' },

  /**
   * Trips
   */
  TRIPS: { METHOD: 'GET', PATH: '/trips', DATA_KEY: 'trip-refs' },
  // TRIPS_SAVED: { METHOD: 'GET', PATH: '/saved-trips', DATA_KEY: 'trip-saved-refs' },
  TRIP_ADD: { METHOD: 'POST', PATH: '/trips', DATA_KEY: 'trip-add' },
  TRIP_CLONE: { METHOD: 'POST', PATH: '/clone-trips/{tripHash}', DATA_KEY: 'trip-add' },
  TRIP_WITH_HASH: { METHOD: 'GET', PATH: '/trips/{hash}', DATA_KEY: 'trip-with-hash' },
  TRIP: { METHOD: 'GET', PATH: '/trips/{tripHash}', DATA_KEY: 'trip' },
  TRIP_UPDATE: { METHOD: 'PUT', PATH: '/trips/{tripHash}', DATA_KEY: 'trip-update' },
  TRIP_DELETE: { METHOD: 'DELETE', PATH: '/trips/{tripHash}', DATA_KEY: 'trip-delete' },
  // TRIPS_SAVED_ADD: { METHOD: 'POST', PATH: '/saved-trips', DATA_KEY: 'trip-saved-refs' },
  // TRIPS_SAVED_DELETE: { METHOD: 'DELETE', PATH: '/saved-trips/{tripHash}', DATA_KEY: 'trip-saved-refs' },
  TRIP_GET_SHARED: { METHOD: 'GET', PATH: '/shared-trips/{tripHash}', DATA_KEY: 'shared-trips' },
  TRIP_SHARE: { METHOD: 'PUT', PATH: '/shared-trips/{tripHash}', DATA_KEY: 'shared-trips-update' },
  TRIP_DOWNLOAD_ICS: { METHOD: 'GET', PATH: '/trips/{tripHash}/ics', DATA_KEY: 'ics' },
  /**
   * Reservations
   */
  RESERVATIONS: { METHOD: 'GET', PATH: '/bookings', DATA_KEY: 'reservations' },
  RESERVATION_ADD: { METHOD: 'POST', PATH: '/bookings', DATA_KEY: 'reservations-add' },
  RESERVATION_UPDATE: { METHOD: 'PUT', PATH: '/bookings/{reservationId}', DATA_KEY: 'reservations-update' },
  RESERVATION_DELETE: { METHOD: 'DELETE', PATH: '/bookings/{reservationId}', DATA_KEY: 'reservations-delete' },

  /**
   * Offers
   */
  OFFER_PRODUCT_TYPES: { METHOD: 'GET', PATH: '/offers/product-types', DATA_KEY: 'offer-product-types' },
  OFFERS: { METHOD: 'GET', PATH: '/offers', DATA_KEY: 'offers' },
  OFFER: { METHOD: 'GET', PATH: '/offers/{offerId}', DATA_KEY: 'offer' },
  OFFER_UPDATE: { METHOD: 'PUT', PATH: '/offers/{offerId}', DATA_KEY: 'offer-update' },
  OFFER_DELETE: { METHOD: 'DELETE', PATH: '/offers/{offerId}', DATA_KEY: 'offer-delete' },
  OFFERS_OPT_IN: { METHOD: 'GET', PATH: '/offers/opt-in', DATA_KEY: 'offer-opt-in' },
  OFFER_REDEEM_STATUS_UPDATE: { METHOD: 'PUT', PATH: '/offer-payment-status/{offerId}', DATA_KEY: 'offer-redeem-status-update' },
  OFFER_REDEEM_STATUS: { METHOD: 'GET', PATH: '/offer-payment-status/{offerId}', DATA_KEY: 'offer-redeem-status' },

  /**
   * Business
   */
  BUSINESS_PRE_REGISTER: { METHOD: 'POST', PATH: '/business/pre-register', DATA_KEY: 'business-pre-register' },
  BUSINESS_SEARCH: { METHOD: 'GET', PATH: '/business/search', DATA_KEY: 'business-search' },
  BUSINESS_USER_ASSIGN: { METHOD: 'PUT', PATH: '/business/user-assign', DATA_KEY: 'user' }, // clear user cahce
  BUSINESS_VERIFY_START: { METHOD: 'POST', PATH: '/business/verify-start', DATA_KEY: 'business-verify-start' },
  BUSINESS_VERIFY_CHECK: { METHOD: 'POST', PATH: '/business/verify-check', DATA_KEY: 'business-verify-check' },
  BUSINESS: { METHOD: 'GET', PATH: '/business', DATA_KEY: 'business' },
  BUSINESS_UPDATE: { METHOD: 'PUT', PATH: '/business', DATA_KEY: 'business-update' },
  BUSINESS_OFFERS: { METHOD: 'GET', PATH: '/business/offers', DATA_KEY: 'business-offers' },
  BUSINESS_OFFER_ADD: { METHOD: 'POST', PATH: '/business/offers', DATA_KEY: 'business-offer-add' },
  BUSINESS_OFFER: { METHOD: 'GET', PATH: '/business/offers/{offerId}', DATA_KEY: 'business-offer' },
  BUSINESS_OFFER_UPDATE: { METHOD: 'PUT', PATH: '/business/offers/{offerId}', DATA_KEY: 'business-offer-update' },
  BUSINESS_OFFER_DELETE: { METHOD: 'DELETE', PATH: '/business/offers/{offerId}', DATA_KEY: 'business-offer-delete' },
  BUSINESS_OFFER_CUSTOMERS: { METHOD: 'GET', PATH: '/business/offers/{offerId}/customers', DATA_KEY: 'business-offer-customers' },
  BUSINESS_OFFER_FILE_UPLOAD: { METHOD: 'POST', PATH: '/business/offers/file-upload', DATA_KEY: 'business-offers-file-upload' },
  BUSINESS_OFFER_REDEEM_STATUS_UPDATE: {
    METHOD: 'PUT',
    PATH: '/business/offer-payment/{offerId}/{userId}',
    DATA_KEY: 'business-offer-redeem-status-update',
  },
  BUSINESS_OFFER_REDEEM_STATUS: {
    METHOD: 'GET',
    PATH: '/business/offer-payment-status/{offerId}/{userId}',
    DATA_KEY: 'business-offer-redeem-status',
  },

  BUSINESS_EXPORT: {
    METHOD: 'GET',
    PATH: '/business/export',
    DATA_KEY: 'business-export',
  },
  /**
   * Recommendations
   */
  RECOMMENDATIONS: { METHOD: 'GET', PATH: '/recommendations', DATA_KEY: 'recommendations' },

  /**
   * Coupon
   */
  BUSINESS_CAMPAIGNS: { METHOD: 'GET', PATH: '/business/campaign', DATA_KEY: 'business-campaigns' },
  BUSINESS_CAMPAIGN: { METHOD: 'GET', PATH: '/business/campaign/{campaignId}', DATA_KEY: 'business-campaign' },
  BUSINESS_CAMPAIGN_ADD: { METHOD: 'POST', PATH: '/business/campaign', DATA_KEY: 'business-campaign-add' },
  BUSINESS_CAMPAIGN_UPDATE: { METHOD: 'PUT', PATH: '/business/campaign/{campaignId}', DATA_KEY: 'business-campaign-update' },

  BUSINESS_COUPON_APPLICATIONS: { METHOD: 'GET', PATH: '/business/coupon-application', DATA_KEY: 'business-coupon-applications' },
  BUSINESS_COUPON_APPLICATION: { METHOD: 'GET', PATH: '/business/coupon-application/{applicationId}', DATA_KEY: 'business-coupon-application' },
  BUSINESS_COUPON_APPLICATION_ADD: { METHOD: 'POST', PATH: '/business/coupon-application', DATA_KEY: 'business-coupon-application-add' },
  BUSINESS_COUPON_APPLICATION_USER_UPDATE: {
    METHOD: 'PUT',
    PATH: '/business/coupon-application/{applicationId}/{travelerId}',
    DATA_KEY: 'business-coupon-application-user-update',
  },
  BUSINESS_COUPON_FILE_UPLOAD: { METHOD: 'POST', PATH: '/business/coupon-file-upload', DATA_KEY: 'business-coupon-file-upload' },

  /**
   * Wallet
   */
  COUPONS: { METHOD: 'GET', PATH: '/coupons', DATA_KEY: 'coupons' },
  PAYMENT: { METHOD: 'POST', PATH: '/offer-payment/{offerId}', DATA_KEY: 'coupon' },

  /**
   * Campaign Report
   */
  CAMPAIGN_REPORT: { METHOD: 'GET', PATH: '/business/campaign-reports/{campaignId}', DATA_KEY: 'campaign-report' },
  CAMPAIGN_REPORT_PAY: { METHOD: 'PUT', PATH: '/business/campaign-reports/{campaignId}/{id}', DATA_KEY: 'campaign-report-pay' },
  CAMPAIGN_CUSTOMER_REPORT: { METHOD: 'GET', PATH: '/business/campaign-customer-reports/{campaignId}', DATA_KEY: 'campaign-customer-report' },
  /**
   * Business Report
   */
  BUSINESS_REPORT: { METHOD: 'GET', PATH: '/business/business-reports', DATA_KEY: 'business-report' },
  BUSINESS_PRE_REGISTER_REPORT: { METHOD: 'GET', PATH: '/business/pre-register-reports', DATA_KEY: 'business-pre-register-report' },
  /**
   * Business Offer Report
   */
  BUSINESS_OFFER_REPORT: { METHOD: 'GET', PATH: '/business/offer-reports', DATA_KEY: 'business-offer-report' },
  /**
   * Translations
   */
  TRANSLATIONS: { METHOD: 'GET', PATH: '/misc/frontend-translations', DATA_KEY: 'translations' },

  /**
   * Logs
   */
  LOGS: { METHOD: 'POST', PATH: '/misc/logs', DATA_KEY: 'logs' },
};
export default API_CONST;
