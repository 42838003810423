/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect, useMemo, useState } from "react";
import moment from "moment-timezone";
import Model, { Providers } from "@tripian/model";
import { NumberCounter, DatePicker, Button, CustomPopover, Notification, SvgIcons, Dropdown } from "@tripian/react";
import classes from "./ViatorTourInfoForm.module.scss";

interface IViatorTourInfoForm {
  formPersonsCategories: Providers.Viator.AgeBand[];
  personsCategories: (Providers.Viator.AgeBand & { count: number })[];
  setPersonsCategories: (personsCategories: (Providers.Viator.AgeBand & { count: number })[]) => void;
  bookableItems: Providers.Viator.BookableItem[];
  productCode: string;
  productURL: string;
  availableDate: string;
  setAvailableDate: (availableDate: string) => void;
  selectedHour: string;
  setSelectedHour: (hour: string) => void;
  checkAvailabilityClick: (tourOptionsRequest: Providers.Viator.AvailabilityCheckRequest) => void;
  maxTravelerCount?: number;
  timeZone?: string;
  t: (value: Model.TranslationKey) => string;
}

const ViatorTourInfoForm: React.FC<IViatorTourInfoForm> = ({
  formPersonsCategories,
  personsCategories,
  setPersonsCategories,
  bookableItems,
  productCode,
  productURL,
  availableDate,
  setAvailableDate,
  selectedHour,
  setSelectedHour,
  checkAvailabilityClick,
  maxTravelerCount,
  timeZone = "UTC",
  t,
}) => {
  moment.locale(window.twindow.langCode);

  const [showPopOver, setShowPopOver] = useState<boolean>(false);
  const [showWarningMessage, setShowWarningMessage] = useState<boolean>(false);

  const minimumDateTime = moment.tz(timeZone).add(1, "day");

  const checkAvailabilityOnClick = () => {
    const availabilityRequest: Providers.Viator.AvailabilityCheckRequest = {
      productCode,
      travelDate: availableDate || "",
      currency: "USD",
      paxMix: personsCategories
        .filter((item) => item.count > 0)
        .map((item) => ({
          ageBand: item.ageBand,
          numberOfTravelers: item.count,
        })),
      startTime: selectedHour !== "" ? selectedHour : undefined,
    };
    checkAvailabilityClick(availabilityRequest);
  };

  const availableDates: string[] = useMemo(() => {
    const availableDatesSet = new Set<string>();

    bookableItems[0].seasons.forEach((season) => {
      const endDate = season.endDate ? moment(season.endDate) : moment().add(1, "year");
      let startDate: moment.Moment;

      if (moment(season.startDate).isBefore(minimumDateTime, "day")) {
        startDate = moment(minimumDateTime);
      } else if (endDate.isAfter(moment(season.startDate), "day")) {
        startDate = moment(season.startDate);
      } else {
        return [];
      }

      const daysOfWeekRecords = season.pricingRecords.filter((record) => record.daysOfWeek);

      const currentDate = moment(startDate);
      while (currentDate.isSameOrBefore(endDate, "day")) {
        if (!startDate || currentDate.isSameOrAfter(moment(startDate), "day")) {
          const dayOfWeek = currentDate.clone().locale("en").format("dddd").toUpperCase(); // "MONDAY", "TUESDAY" vb.

          if (Array.isArray(daysOfWeekRecords) && daysOfWeekRecords.length > 0) {
            const isDayOfWeekAvailable = daysOfWeekRecords.some((record) => record.daysOfWeek.includes(dayOfWeek));

            if (isDayOfWeekAvailable) {
              const unavailableDates = daysOfWeekRecords.flatMap((record) =>
                (record.timedEntries ?? []).flatMap((entry) => (entry.unavailableDates ? entry.unavailableDates.map((dateEntry) => dateEntry.date) : []))
              );
              if (!unavailableDates.includes(currentDate.format("YYYY-MM-DD"))) {
                availableDatesSet.add(currentDate.format("YYYY-MM-DD"));
              }
            }
          }
        }
        currentDate.add(1, "day");
      }
    });

    return Array.from(availableDatesSet);
  }, [bookableItems, minimumDateTime]);

  const getAvailableHoursForDate = (date: string) => {
    const availableHours = new Set<string>();
    const targetDate = moment(date);
    const dayOfWeek = targetDate.clone().locale("en").format("dddd").toUpperCase(); // "MONDAY", "TUESDAY" vb.

    let hasTimedEntries = false; // Timed entries olup olmadığını kontrol etmek için bayrak
    let hasOperatingHours = false; // Operating hours olup olmadığını kontrol etmek için bayrak

    bookableItems.forEach((item) => {
      item.seasons.forEach((season) => {
        const dayRecord = season.pricingRecords?.find((record) => record.daysOfWeek?.includes(dayOfWeek));

        if (dayRecord) {
          // Check for `timedEntries` and add the entries to `availableHours` as before
          if (dayRecord.timedEntries) {
            hasTimedEntries = true;
            dayRecord.timedEntries.forEach((entry) => {
              const isSoldOut = entry.unavailableDates?.some((unavailableDate) => moment(unavailableDate.date).isSame(targetDate, "day"));

              // Include only unsold and future times
              if (!isSoldOut) {
                const entryTime = moment(`${date} ${entry.startTime}`, "YYYY-MM-DD HH:mm");
                if (entryTime.isAfter(minimumDateTime)) {
                  availableHours.add(entry.startTime);
                }
              }
            });
          }

          if (season.operatingHours?.some((op) => op.dayOfWeek === dayOfWeek)) {
            hasOperatingHours = true;
          }
        }
      });
    });

    if (!hasTimedEntries && hasOperatingHours) {
      return undefined;
    }

    return Array.from(availableHours)
      .filter((value, index, self) => self.indexOf(value) === index)
      .sort((a, b) => parseInt(a) - parseInt(b));
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const availableHours = useMemo(() => getAvailableHoursForDate(availableDate), [availableDate, bookableItems]);

  useEffect(() => {
    if (availableDate) {
      const availableHours = getAvailableHoursForDate(availableDate);

      // İlk uygun saati setle, yoksa boş string setle
      if (availableHours && availableHours.length > 0) {
        setSelectedHour(availableHours[0]);
      } else {
        setSelectedHour("");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [availableDate]);

  const isDayBlocked = (date: moment.Moment | null) => {
    if (date) {
      const hours = getAvailableHoursForDate(date?.format("YYYY-MM-DD"));
      // undefined dönerse blocked değil (false), boş dizi dönerse blocked (true)
      return hours !== undefined && hours.length === 0;
    }
    return false;
  };

  if (window.tconfig.PROVIDERS.tourAndTicket.find((t) => t.id === Model.PROVIDER_ID.VIATOR)?.prod === true) {
    return (
      <div className={classes.mainDiv}>
        <div className="center">
          <Button color="primary" className={classes.addToChartButton} onClick={() => window.open(productURL)} text={t("trips.myTrips.localExperiences.tourDetails.bookNow")} />
        </div>
      </div>
    );
  }
  return (
    <div className={classes.gygTourInfoForm}>
      {availableDates.length === 0 ? (
        <span className={classes.warningMessage}>{t("trips.myTrips.localExperiences.tourDetails.noAvailableDays")}</span>
      ) : (
        <>
          <h3 className={classes.gygTourInfoFormHeader}>{t("trips.myTrips.localExperiences.tourDetails.selectParticipantsAndDate")}</h3>
          <ul className={classes.gygTourInfoFormList}>
            <li>
              <div className={classes.viatorTourInfoDatePicker}>
                {availableDate && availableDates.length > 0 && (
                  <DatePicker
                    currentDate={moment(availableDate)}
                    startDate={moment(availableDates[0])}
                    endDate={moment(availableDates[availableDates.length - 1]).add("day", 1)}
                    onchanged={(date) => {
                      if (date) {
                        const selectedDate = date.format("YYYY-MM-DD");
                        setAvailableDate(selectedDate);

                        // Get available hours for the selected date
                        const availableHours = getAvailableHoursForDate(selectedDate);

                        // Automatically set the first available hour, if any
                        if (availableHours && availableHours.length > 0) {
                          setSelectedHour(availableHours[0]);
                        }
                      }
                    }}
                    isDayBlocked={isDayBlocked}
                  />
                )}
              </div>
            </li>
            {availableDate && availableHours && (
              <li className="min-w-32 max-[768px]:mt-4">
                <div className={classes.viatorHourSelect}>
                  <Dropdown
                    id="hourSelect"
                    options={availableHours
                      .filter((value, index) => getAvailableHoursForDate(availableDate)?.indexOf(value) === index)
                      .sort((a, b) => parseInt(a) - parseInt(b))
                      .map((hour) => ({
                        value: hour,
                        label: hour,
                      }))}
                    defaultValue={availableHours.length === 0 ? t("trips.myTrips.localExperiences.tourDetails.pleaseSelect") : selectedHour}
                    selectChange={(e) => setSelectedHour(e.toString())}
                    disabled={availableHours.length === 0}
                  />
                </div>
              </li>
            )}
            {/* {availableHours.length === 0 && <div className={classes.noHourAvailableMessage}>No specific time required for this date</div>} */}
            <CustomPopover
              positions={["bottom"]}
              show={showPopOver}
              backdropClick={(e) => {
                e.stopPropagation();
                setShowPopOver(false);
              }}
              content={
                <div className={classes.gygTourInfoListItemWrapper}>
                  {formPersonsCategories.map((formPersonsCategory, i) => {
                    const count: number = personsCategories.find((x) => x.ageBand === formPersonsCategory.ageBand)?.count ?? 0;
                    return (
                      <li key={`${formPersonsCategory.ageBand}-${i}`} className={classes.gygTourInfoFormListItem}>
                        <div>
                          <h4 className={classes.gygTourInfoPersonCountText}>
                            <div>{formPersonsCategory.ageBand}</div>
                          </h4>
                          <div className={classes.gygTourInfoPersonAge}>
                            ({t("trips.myTrips.localExperiences.tourDetails.age")}: {formPersonsCategory.startAge} - {formPersonsCategory.endAge})
                          </div>
                        </div>

                        <div className={classes.gygTourInfoPersonCount}>
                          {personsCategories.length > 0 && (
                            <NumberCounter
                              defaultValue={count}
                              minValue={0}
                              maxValue={100}
                              iconFill="blue"
                              onchange={(newCount) => {
                                const newPersonsCategories = [...personsCategories];
                                const findX = newPersonsCategories.findIndex((x) => x.ageBand === formPersonsCategory.ageBand);

                                if (findX > -1) {
                                  newPersonsCategories[findX].count = newCount;

                                  if (maxTravelerCount && newPersonsCategories.reduce((accumulator, currentValue) => accumulator + currentValue.count, 0) > maxTravelerCount) {
                                    setShowWarningMessage(true);
                                  } else {
                                    setPersonsCategories(newPersonsCategories);
                                  }
                                }
                              }}
                            />
                          )}
                        </div>
                      </li>
                    );
                  })}
                  <h3 className={classes.viatorTourInfoFormMaxWarning}>
                    {t("trips.myTrips.localExperiences.tourDetails.maxTravelerYouCanBringAlong")} {maxTravelerCount}
                  </h3>
                </div>
              }
            >
              <div
                onClick={() => {
                  setShowPopOver(!showPopOver);
                }}
                role="button"
                onKeyDown={() => {}}
                tabIndex={0}
                className={classes.gygTourInfoPeopleButton}
                key="viator-tour-info"
              >
                <SvgIcons.Avatar />
                {personsCategories
                  .filter((p) => p.count !== 0)
                  .map((personsCategory, index, self) => {
                    const comma = index + 1 !== self.length ? "," : "";
                    if (personsCategory.ageBand === "CHILD" && personsCategory.count > 1) {
                      //Children Edge case
                      return (
                        <span key={`${personsCategory.ageBand}-${personsCategory.count}-${index}-c`}>
                          {personsCategory.count} {t("trips.toursAndTickets.children.label")}
                          {comma}
                        </span>
                      );
                    } else if (personsCategory.count > 1) {
                      //e.g Adults
                      return (
                        <span key={`${personsCategory.ageBand}-${personsCategory.count}-${index}-i`}>
                          {personsCategory.count} {personsCategory.ageBand[0] + personsCategory.ageBand.toLowerCase().slice(1)}s{comma}
                        </span>
                      );
                    }
                    //e.g Adult
                    return (
                      <span key={`${personsCategory.ageBand}-${personsCategory.count}-${index}-a`}>
                        {personsCategory.count} {personsCategory.ageBand[0] + personsCategory.ageBand.toLowerCase().slice(1)}
                        {comma}
                      </span>
                    );
                  })}
              </div>
            </CustomPopover>
          </ul>
          {showWarningMessage ? (
            <Notification
              type="warning"
              positionX="center"
              positionY="top"
              title={t("trips.myTrips.localExperiences.tourDetails.maxTravelerAmountReached")}
              message={t("trips.myTrips.localExperiences.tourDetails.pleaseLowerTheTravelerCount")}
              onClose={() => {
                setShowWarningMessage(false);
              }}
              closeMs={3500}
            />
          ) : null}

          <Button
            disabled={
              !availableDate ||
              (availableHours && availableHours.length > 0 && !selectedHour) ||
              (maxTravelerCount !== undefined && personsCategories.reduce((accumulator, currentValue) => accumulator + currentValue.count, 0) > maxTravelerCount)
            }
            className={classes.checkAvailabilityButton}
            onClick={checkAvailabilityOnClick}
            text={t("trips.myTrips.localExperiences.tourDetails.checkAvailability")}
          />
        </>
      )}
    </div>
  );
};

export default ViatorTourInfoForm;
