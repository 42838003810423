import React, { useMemo, useState } from "react";
import { api } from "@tripian/core";
import useTranslate from "../../hooks/useTranslate";
import { Button, DestinationSelect, ImgLazy, PreLoading } from "@tripian/react";
import AppNav from "../../App/AppNav/AppNav";
import { CITY_INFO } from "../../constants/ROUTER_PATH_TITLE";
import useCities from "../../hooks/useCities";
import Model, { helper } from "@tripian/model";
import classes from "./CityInfoPage.module.scss";

export type RSelectOption = {
  id: number;
  label: string;
  payload: { destinationId: number; destinationName: string; parentName: string };
  isSelected?: boolean;
};

const CityInfoPage = () => {
  const { cities, loadingCities } = useCities();
  const [selectedCityId, setSelectedCityId] = useState<number>();
  const [loading, setLoading] = useState<boolean>(false);
  const [info, setInfo] = useState<{ city: Model.City; details: Model.CityInfo }>();
  const [error, setError] = useState<string>();
  const { t } = useTranslate();

  const destinations: { destinationId: number; destinationName: string; parentName: string }[] = useMemo(() => {
    if (cities) {
      return cities?.map((city) => {
        const destination = {
          destinationId: city.id,
          destinationName: city.name,
          parentName: city.parentLocations.length === 0 ? city.country.name : `${city.parentLocations.map((parent) => parent.name).join(", ")}, ${city.country.name}`,
        };

        return destination;
      });
    }
    return [];
  }, [cities]);

  const destinationOptions: RSelectOption[] = useMemo(
    () =>
      destinations
        .sort((a, b) => helper.compareStringForSort(a.destinationName, b.destinationName))
        .map((d) => ({
          id: d.destinationId,
          label: `${d.destinationName} - ${d.parentName}`,
          payload: d,
          isSelected: d.destinationId === selectedCityId,
        })),
    [destinations, selectedCityId]
  );

  const handleCityInfoFetch = async () => {
    if (selectedCityId) {
      setLoading(true);
      try {
        const response = await api.cityInfo(selectedCityId);
        const city = cities?.find((city) => city.id === selectedCityId);
        if (city) {
          const info = { city, details: response };
          setInfo(info);
          setError(undefined);
        }
        setLoading(false);
      } catch (error: any) {
        setInfo(undefined);
        setError(error);
        setLoading(false);
      }
    }
  };

  const handleCitySelection = (selectedOption: RSelectOption) => {
    setSelectedCityId(selectedOption.id);
  };

  /* */
  /* */
  /* ********* CITY INFO ********* */
  const cityImgDiv = useMemo(() => {
    if (info) {
      return (
        <div className="row m0">
          <div className="col col12 col5-m">
            <div style={{ borderRadius: "8px", overflow: "hidden" }}>
              <ImgLazy src={info.city ? helper.cityImgUrl(info.city.image.url || "", 800, 500) : ""} alt="" x={800} y={500} showThumbnails={info.city !== undefined} />
            </div>
          </div>
          <div className="col col12 col7-m">
            <h1 className="py4 text-2xl text-gray-800">
              <strong>{info.city?.name}</strong>
            </h1>
            <p className="text-gray-700 text-base">{info.city?.description}</p>
          </div>
        </div>
      );
    }
    return undefined;
  }, [info]);

  const cityInfoDetails = useMemo(() => {
    if (info) {
      const { wifiInformation, lifeQualityIndices, emergencyNumbers, powerInformation, bestTimeToVisit } = info.details.information;

      return (
        <div className="city-info-details space-y-4 mt-8">
          <h2 className="text-2xl font-semibold text-gray-800 mb-4 text-center">{t("cityInfo.additionalCityInformation")}</h2>

          {wifiInformation && (
            <div className="card bg-white shadow-md rounded-lg p-6">
              <h3 className="text-xl font-semibold text-gray-700 mb-2">{t("cityInfo.wifiInformation.label")}</h3>
              <p>
                <strong>{t("cityInfo.wifiInformation.mobile")}:</strong> {wifiInformation.mobile}
              </p>
              <p>
                <strong>{t("cityInfo.wifiInformation.broadband")}:</strong> {wifiInformation.broadband}
              </p>
            </div>
          )}

          {lifeQualityIndices && (
            <div className="card bg-white shadow-md rounded-lg p-6">
              <h3 className="text-xl font-semibold text-gray-700 mb-2">{t("cityInfo.lifeQualityIndices.label")}</h3>
              <p>
                <strong>{t("cityInfo.lifeQualityIndices.safetyIndex")}:</strong> {lifeQualityIndices.safetyIndex.rating} ({lifeQualityIndices.safetyIndex.value})
              </p>
              <p>
                <strong>{t("cityInfo.lifeQualityIndices.healthCareIndex")}:</strong> {lifeQualityIndices.healthCareIndex.rating} ({lifeQualityIndices.healthCareIndex.value})
              </p>
              <p>
                <strong>{t("cityInfo.lifeQualityIndices.propertyPriceToIncomeRatio")}:</strong> {lifeQualityIndices.propertyPriceToIncomeRatio.rating} (
                {lifeQualityIndices.propertyPriceToIncomeRatio.value})
              </p>
              <p>
                <strong>{t("cityInfo.lifeQualityIndices.trafficCommuteTimeIndex")}:</strong> {lifeQualityIndices.trafficCommuteTimeIndex.rating} (
                {lifeQualityIndices.trafficCommuteTimeIndex.value})
              </p>
              <p>
                <strong>{t("cityInfo.lifeQualityIndices.purchasingPowerIndex")}:</strong> {lifeQualityIndices.purchasingPowerIndex.rating} (
                {lifeQualityIndices.purchasingPowerIndex.value})
              </p>
              <p>
                <strong>{t("cityInfo.lifeQualityIndices.qualityOfLifeIndex")}:</strong> {lifeQualityIndices.qualityOfLifeIndex.rating} (
                {lifeQualityIndices.qualityOfLifeIndex.value})
              </p>
              <p>
                <strong>{t("cityInfo.lifeQualityIndices.climateIndex")}:</strong> {lifeQualityIndices.climateIndex.rating} ({lifeQualityIndices.climateIndex.value})
              </p>
              <p>
                <strong>{t("cityInfo.lifeQualityIndices.pollutionIndex")}:</strong> {lifeQualityIndices.pollutionIndex.rating} ({lifeQualityIndices.pollutionIndex.value})
              </p>
              <p>
                <strong>{t("cityInfo.lifeQualityIndices.costOfLivingIndex")}:</strong> {lifeQualityIndices.costOfLivingIndex.rating} ({lifeQualityIndices.costOfLivingIndex.value})
              </p>
            </div>
          )}

          {emergencyNumbers && (
            <div className="card bg-white shadow-md rounded-lg p-6">
              <h3 className="text-xl font-semibold text-gray-700 mb-2">{t("cityInfo.emergencyNumbers.label")}</h3>
              <p>
                <strong>{t("cityInfo.emergencyNumbers.fire")}:</strong> {emergencyNumbers.fire}
              </p>
              <p>
                <strong>{t("cityInfo.emergencyNumbers.police")}:</strong> {emergencyNumbers.police}
              </p>
              <p>
                <strong>{t("cityInfo.emergencyNumbers.ambulance")}:</strong> {emergencyNumbers.ambulance}
              </p>
              {emergencyNumbers.notes && (
                <p>
                  <strong>{t("cityInfo.emergencyNumbers.notes")}:</strong> {emergencyNumbers.notes}
                </p>
              )}
            </div>
          )}

          {powerInformation && (
            <div className="card bg-white shadow-md rounded-lg p-6">
              <h3 className="text-xl font-semibold text-gray-700 mb-2">{t("cityInfo.powerInformation.label")}</h3>
              <p>
                <strong>{t("cityInfo.powerInformation.plugs")}:</strong> {powerInformation.plugs.join(", ")}
              </p>
              <p>
                <strong>{t("cityInfo.powerInformation.voltage")}:</strong> {powerInformation.voltage}
              </p>
              <p>
                <strong>{t("cityInfo.powerInformation.frequency")}:</strong> {powerInformation.frequency}
              </p>
            </div>
          )}

          {bestTimeToVisit && (
            <div className="card bg-white shadow-md rounded-lg p-6">
              <h3 className="text-xl font-semibold text-gray-700 mb-2">{t("cityInfo.bestTimeToVisit.label")}</h3>
              <p>
                <strong>{t("cityInfo.bestTimeToVisit.notes")}:</strong> {bestTimeToVisit.notes}
              </p>
              <p>
                <strong>{t("cityInfo.bestTimeToVisit.offSeason")}:</strong> {bestTimeToVisit.offSeason.description} ({t("cityInfo.bestTimeToVisit.months")}:{" "}
                {bestTimeToVisit.offSeason.months.join(", ")})
              </p>
              <p>
                <strong>{t("cityInfo.bestTimeToVisit.peakSeason")}:</strong> {bestTimeToVisit.peakSeason.description} ({t("cityInfo.bestTimeToVisit.months")}:{" "}
                {bestTimeToVisit.peakSeason.months.join(", ")})
              </p>
              <p>
                <strong>{t("cityInfo.bestTimeToVisit.shoulderSeason")}:</strong> {bestTimeToVisit.shoulderSeason.description} ({t("cityInfo.bestTimeToVisit.months")}:{" "}
                {bestTimeToVisit.shoulderSeason.months.join(", ")})
              </p>
            </div>
          )}
        </div>
      );
    }
    return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [info]);

  /* ********* CITY INFO ********* */
  /* */
  /* */

  return (
    <div>
      <AppNav header={CITY_INFO.HEADER?.(t("cityInfo.title"))} />
      {loadingCities ? (
        <div className={classes.loadingWrapper}>
          <PreLoading />
        </div>
      ) : (
        <div className="w-full">
          <div className="w-5/6 sm:w-5/6 md:w-4/6 lg:w-3/6 my-8 mx-auto">
            <DestinationSelect
              options={destinationOptions}
              selectedOptionId={selectedCityId}
              onSelectedOptionChange={handleCitySelection}
              placeHolder={t("trips.createNewTrip.form.destination.city.placeholder")}
            />

            <div className="row center mt-6">
              <Button text={t("trips.toursAndTickets.submit")} color="primary" onClick={() => handleCityInfoFetch()} />
            </div>
          </div>
          <hr className="h-px my-4 bg-gray-100 border-0" />

          {loading ? (
            <div className={classes.loadingWrapper}>
              <PreLoading />
            </div>
          ) : (
            <div className="city-info p-10">
              {info && (
                <>
                  {cityImgDiv} {cityInfoDetails}
                </>
              )}
              {error && <div className="text-center">{error}</div>}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default CityInfoPage;
