import React from 'react';
import Svg from '../Svg';

interface ITour {
  fill?: string;
  color?: string;
  size?: string;
  className?: string;
}

const Tour: React.FC<ITour> = ({ fill, color, size = '20px', className }) => (
  <Svg
    fill={fill}
    color={color}
    size={size}
    path="M490.6,121.5H326l-19.6-39.2c-1.8-3.5-5.4-5.8-9.3-5.8h-82.3c-3.9,0-7.6,2.2-9.3,5.8L186,121.5H21.4    c-5.8,0-10.4,4.7-10.4,10.4V425c0,5.8,4.7,10.4,10.4,10.4h469.1c5.8,0,10.4-4.7,10.4-10.4V132C501,126.2,496.3,121.5,490.6,121.5z     M480.1,414.6H31.9V142.4h160.6c3.9,0,7.6-2.2,9.3-5.8l19.6-39.2h69.4l19.6,39.2c1.8,3.5,5.4,5.8,9.3,5.8h160.6V414.6z"
    path2="m147.8,278.5c0,59.7 48.5,108.2 108.2,108.2 59.7,0 108.2-48.5 108.2-108.2s-48.5-108.2-108.2-108.2c-59.7,0-108.2,48.5-108.2,108.2zm195.6,0c0,48.2-39.2,87.4-87.4,87.4s-87.4-39.2-87.4-87.4 39.2-87.4 87.4-87.4 87.4,39.2 87.4,87.4z"
    viewBox="0 0 512 512"
    className={className}
  >
    <path d="m434.7,228c5.8,0 10.4-4.7 10.4-10.4v-29.6c0-5.8-4.7-10.4-10.4-10.4-5.8,0-10.4,4.7-10.4,10.4v29.6c0,5.7 4.6,10.4 10.4,10.4z" />
  </Svg>
);

export default Tour;
